<template>
  <div class="collection">
    <div class="collection__list">
      <div
        class="collection__item"
        v-for="(item, index) in list"
        v-bind:key="item.scryfallId + '_' + index"
      >
        <div class="collection__item--quantity" v-html="item.quantity" />
        <div class="collection__item--base">
          <div class="collection__item--row">
            <div class="collection__item--name" v-html="item.name"></div>
            <div class="collection__item--rarity" :class="'collection__rarity--'+item.rarity"></div>
          </div>
          <div class="collection__item--row">
            <div class="collection__item--type" v-html="item.type"></div>
            <div class="collection__item--foil" v-if="item.isFoil"></div>
          </div>
          <div class="collection__item--row">
            <div class="collection__item--edition" v-html="item.edition"></div>
            <div class="collection__item--manaValue" v-html="formatManaValue(item.manaValue)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.component('Collection', {
  props: {
    list: { default: null, type: Array },
  },
  data() {
    return {}
  },
  methods: {
    formatManaValue(manaValueStr) {
      manaValueStr = manaValueStr
        // Colorless
        .replaceAll('{C}', '<span class="mana-pivot mana-pivot--c"></span>')

        // Standard colors
        .replaceAll('{W}', '<span class="mana-pivot mana-pivot--w"></span>')
        .replaceAll('{U}', '<span class="mana-pivot mana-pivot--u"></span>')
        .replaceAll('{B}', '<span class="mana-pivot mana-pivot--b"></span>')
        .replaceAll('{R}', '<span class="mana-pivot mana-pivot--r"></span>')
        .replaceAll('{G}', '<span class="mana-pivot mana-pivot--g"></span>')

        // Phyrexian
        .replaceAll('{W/P}', '<span class="mana-pivot mana-pivot--wp">|</span>')
        .replaceAll('{U/P}', '<span class="mana-pivot mana-pivot--up">|</span>')
        .replaceAll('{B/P}', '<span class="mana-pivot mana-pivot--bp">|</span>')
        .replaceAll('{R/P}', '<span class="mana-pivot mana-pivot--rp">|</span>')
        .replaceAll('{G/P}', '<span class="mana-pivot mana-pivot--gp">|</span>')

        // Generic og colored
        .replaceAll('{2/W}', '<span class="mana-pivot mana-pivot--2w"></span>')
        .replaceAll('{2/U}', '<span class="mana-pivot mana-pivot--2u"></span>')
        .replaceAll('{2/B}', '<span class="mana-pivot mana-pivot--2b"></span>')
        .replaceAll('{2/R}', '<span class="mana-pivot mana-pivot--2r"></span>')
        .replaceAll('{2/G}', '<span class="mana-pivot mana-pivot--2g"></span>')

        // Hybrids
        .replaceAll('{W/B}', '<span class="mana-pivot mana-pivot--wb"></span>')
        .replaceAll('{W/U}', '<span class="mana-pivot mana-pivot--wu"></span>')
        .replaceAll('{R/W}', '<span class="mana-pivot mana-pivot--rw"></span>')
        .replaceAll('{G/W}', '<span class="mana-pivot mana-pivot--gw"></span>')
        .replaceAll('{U/R}', '<span class="mana-pivot mana-pivot--ur"></span>')
        .replaceAll('{U/B}', '<span class="mana-pivot mana-pivot--ub"></span>')
        .replaceAll('{G/U}', '<span class="mana-pivot mana-pivot--gu"></span>')
        .replaceAll('{B/R}', '<span class="mana-pivot mana-pivot--br"></span>')
        .replaceAll('{B/G}', '<span class="mana-pivot mana-pivot--bg"></span>')
        .replaceAll('{R/G}', '<span class="mana-pivot mana-pivot--rg"></span>')

        // Generics
        .replaceAll('{X}', '<span class="mana-pivot mana-pivot--x"></span>')
        .replaceAll('{0}', '<span class="mana-pivot mana-pivot--0"></span>')
        .replaceAll('{1}', '<span class="mana-pivot mana-pivot--1"></span>')
        .replaceAll('{2}', '<span class="mana-pivot mana-pivot--2"></span>')
        .replaceAll('{3}', '<span class="mana-pivot mana-pivot--3"></span>')
        .replaceAll('{4}', '<span class="mana-pivot mana-pivot--4"></span>')
        .replaceAll('{5}', '<span class="mana-pivot mana-pivot--5"></span>')
        .replaceAll('{6}', '<span class="mana-pivot mana-pivot--6"></span>')
        .replaceAll('{7}', '<span class="mana-pivot mana-pivot--7"></span>')
        .replaceAll('{8}', '<span class="mana-pivot mana-pivot--8"></span>')
        .replaceAll('{9}', '<span class="mana-pivot mana-pivot--9"></span>')
        .replaceAll('{10}', '<span class="mana-pivot mana-pivot--10"></span>')
        .replaceAll('{11}', '<span class="mana-pivot mana-pivot--11"></span>')
        .replaceAll('{12}', '<span class="mana-pivot mana-pivot--12"></span>')
        .replaceAll('{13}', '<span class="mana-pivot mana-pivot--13"></span>')
        .replaceAll('{14}', '<span class="mana-pivot mana-pivot--14"></span>')
        .replaceAll('{15}', '<span class="mana-pivot mana-pivot--15"></span>')
        .replaceAll('{16}', '<span class="mana-pivot mana-pivot--16"></span>')
        .replaceAll('{17}', '<span class="mana-pivot mana-pivot--17"></span>')
        .replaceAll('{18}', '<span class="mana-pivot mana-pivot--18"></span>')
        .replaceAll('{19}', '<span class="mana-pivot mana-pivot--19"></span>')
        .replaceAll('{20}', '<span class="mana-pivot mana-pivot--20"></span>')

      return manaValueStr
    },
  },
})
</script>

<style lang="scss">
@import 'collection.scss';
</style>
