<template>
  <div>
    <div class="user-control">
      <div class="user-control__toggler" @click.prevent="toggle" />
      <Overlay ref="overlay">
        <slot>
          <div v-if="!isLoggedInData" class="user-control__menu-item pushDown">
            <input
              class="block user-control__menu-sub-item pushDown"
              type="text"
              placeholder="Email..."
              v-model="email"
            />
            <input
              class="block user-control__menu-sub-item pushDown"
              type="password"
              placeholder="Password..."
              v-model="password"
              @keyup.enter="login"
            />
            <button
              class="button button--green"
              @click.prevent="login"
            >
              Login
            </button>
          </div>
          <div v-if="!isLoggedInData" class="user-control__menu-item pushDown">
            <button
              class="button button--green"
              @click.prevent="openRegistrationDialog"
            >
              Register
            </button>
          </div>
          <div v-if="isLoggedInData" class="user-control__menu-item pushDown">
            <button class="button button--green">
              My decks
            </button>
          </div>
          <div v-if="isLoggedInData" class="user-control__menu-item">
            <button
              class="button button--green button--reversed"
              @click.prevent="logout"
            >
              Logout
            </button>
          </div>
        </slot>
      </Overlay>
    </div>
    <Dialog :event-bus="eventBus" :is-open="registrationDialogOpen">
      <slot><UserRestration :event-bus="eventBus" /></slot>
    </Dialog>
  </div>
</template>

<script>
import Vue from 'vue'

import Dialog from '@components/Dialog/Dialog.vue'
import UserRestration from '@components/UserRegistration/UserRegistration.vue'
import Overlay from '@components/Overlay/Overlay.vue'

export default Vue.component('UserControl', {
  components: {
    Dialog,
    UserRestration,
    Overlay,
  },
  props: {
    isLoggedIn: { default: false, type: Boolean },
  },
  data() {
    return {
      eventBus: new Vue(),
      email: '',
      password: '',
      isLoggedInData: this.isLoggedIn,
      errorMessage: '',
      registrationDialogOpen: false,
    }
  },
  methods: {
    toggle() {
      this.$refs.overlay.open()
    },
    login() {
      const data = JSON.stringify({
        Email: this.email,
        Password: this.password,
      })
      this.axios.post('/User/Login/', data).then(resp => {
        if (resp?.data === null || resp?.data === undefined) return

        if (resp.data.errorMessage !== null) {
          this.errorMessage = resp.data.errorMessage
        } else if (resp?.data?.data !== undefined) {
          this.isLoggedInData = true
          this.globalEventBus.$emit('user-logged-in', true)
        }
      })
    },
    logout() {
      this.axios.post('/User/Logout/').then(resp => {
        if (resp?.data === null || resp?.data === undefined) return
        this.isLoggedInData = false
        this.globalEventBus.$emit('user-logged-in', false)
      })
    },
    openRegistrationDialog() {
      this.registrationDialogOpen = true
    },
    closeRegistrationDialog() {
      this.registrationDialogOpen = false
    },
  },
  watch: {
    isLoggedIn(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$refs.overlay.close()
      }
    },
  },
  mounted() {
    this.eventBus.$on('dialog-closing', this.closeRegistrationDialog)
  },
})
</script>

<style lang="scss">
@import 'user-control.scss';
</style>
