<template>
  <div class="dialog__container" @click.prevent="requestClosing" v-if="isOpen">
    <div class="dialog__overlay"></div>
    <div class="dialog" @click.prevent="keepOpen">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.component('Dialog', {
  props: {
    eventBus: { default: null, type: Object },
    isOpen: { default: false, type: Boolean },
  },
  data() {
    return {}
  },
  methods: {
    keepOpen() { },
    requestClosing() {
      this.eventBus.$emit('dialog-closing')
    },
  },
  mounted() { },
})
</script>

<style lang="scss">
@import 'dialog.scss';
</style>
