<template>
  <div class="user-registration">
    <h3>User registration</h3>
    <p>Username</p>
    <input type="text" name="username" v-model="username" />
    <p>Email</p>
    <input type="text" name="email" v-model="email" />
    <p>Password</p>
    <input type="password" name="password" v-model="password" />
    <p>Retype password</p>
    <input
      type="password"
      name="passwordVerification"
      v-model="passwordVerification"
    />
    <div class="user-registration__button-panel">
      <button class="user-registration__cancel" @click.prevent="requestClosing">
        cancel
      </button>
      <button class="user-registration__submit" @click.prevent="register">
        OK
      </button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

import { isNullOrEmpty, validateEmail } from '@/functions'

export default Vue.component('UserRegistration', {
  props: {
    eventBus: { default: null, type: Object },
  },
  data() {
    return {
      username: '',
      email: '',
      password: '',
      passwordVerification: '',

      errorCollection: [],
    }
  },
  methods: {
    validate() {
      this.errorCollection = []

      if (isNullOrEmpty(this.username)) {
        this.errorCollection['username'] = 'Enter username'
      }
      if (isNullOrEmpty(this.email) || !validateEmail(this.email)) {
        this.errorCollection['username'] = 'Enter valid email'
      }
      if (isNullOrEmpty(this.password)) {
        this.errorCollection['password'] = 'Enter password'
      }
      if (isNullOrEmpty(this.passwordVerification)) {
        this.errorCollection['passwordVerification'] =
          'Reenter password to verify'
      }
      if (
        !isNullOrEmpty(this.password) &&
        this.password !== this.passwordVerification
      ) {
        this.errorCollection['passwordVerification'] = "Passwords doesn't match"
      }

      return this.errorCollection.length === 0
    },
    register() {
      if (!this.validate) return

      const data = JSON.stringify({
        Username: this.username,
        Email: this.email,
        Password: this.password,
      })
      this.axios.post('/User/Register/', data).then(resp => {
        if (resp?.data === null || resp?.data === undefined) return

        if (resp.data.errorMessage !== null) {
          this.errorCollection[global] = resp.data.errorMessage
        } else if (resp?.data?.data !== undefined) {
          this.isLoggedInData = true
        }
      })
    },
    requestClosing() {
      this.eventBus.$emit('dialog-closing')
    },
  },
  mounted() { },
})
</script>

<style lang="scss">
@import 'user-registration.scss';
</style>
