<script>
import Vue from 'vue'

import Presentation from '@components/PresentationContainer/Presentation.vue'

export default Vue.component('PresentationContainer', {
  template: '#PresentationContainerTemplate',
  components: {
    Presentation,
  },
})
</script>
