<template>
  <div class="overlay__container" v-if="isOpen">
    <div class="overlay__close" @click.prevent="close">X</div>
    <div class="overlay">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.component('UserControl', {
  data() {
    return {
      isOpen: false,
    }
  },
  methods: {
    open() {
      this.isOpen = true
    },
    close() {
      this.isOpen = false
    },
  },
  mounted() {},
})
</script>

<style lang="scss">
@import 'overlay.scss';
</style>
