export default {}

/******************/
/* IS OBJECT NULL */
/******************/
export function isNull(object) {
  if (
    object === null ||
    object === undefined ||
    (Object.keys(object).length === 0 && object.constructor === Object)
  )
    return true
  return false
}

/***************************/
/* IS OBJECT NULL OR EMPTY */
/***************************/

export function isNullOrEmpty(object) {
  if (isNull(object) || object === '') return true
  return false
}

/********************/
/* Email validation */
/********************/
export function validateEmail(emailVal) {
  var re =
    //eslint-disable-next-line
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  return re.test(String(emailVal).toLowerCase())
}