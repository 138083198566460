<template>
  <div class="presentation">
    <div class="presentation__header">
      <UserControl :is-logged-in="isLoggedIn" />
      <button v-if="isLoggedIn" class="btn btn-info" @click="onPickFile">
        Upload via CSV
      </button>
      <input
        type="file"
        style="display: none"
        ref="csvUpload"
        accept="csv"
        @change="onFilePicked"
      />
      <SearchBar :event-bus="eventBus" :result-count="collectionList.length" />
    </div>
    <Collection :list="collectionList" />
  </div>
</template>

<script>
import Vue from 'vue'

import Collection from '@components/Collection/Collection.vue'
import SearchBar from '@components/SearchBar/SearchBar.vue'
import UserControl from '@components/UserControl/UserControl.vue'

export default Vue.component('Presentation', {
  components: {
    Collection,
    SearchBar,
    UserControl,
  },
  props: {
    isLoggedInInitial: { default: false, type: Boolean },
  },
  data() {
    return {
      eventBus: new Vue(),
      isLoggedIn: this.isLoggedIn,
      collectionList: [],
    }
  },
  methods: {
    onPickFile() {
      this.$refs.csvUpload.click()
    },
    onFilePicked(event) {
      const files = event.target.files

      var reader = new FileReader()
      reader.onload = loadEvent => {
        const arrayBuffer = loadEvent.target.result
        const fileByteArray = []
        const array = new Uint8Array(arrayBuffer)

        for (let i = 0; i < array.length; i++) {
          fileByteArray.push(array[i])
        }
        const data = {
          FileBytes: JSON.stringify(fileByteArray),
        }
        this.axios.post('/Upload/UploadCsv/', data).then(resp => {
          this.collectionList = resp.data
        })
      }
      reader.readAsArrayBuffer(files[0])
    },
    performSearch(criterias, textSearch) {
      const data = JSON.stringify({
        TextSearch: textSearch,
        CriteriaList: criterias,
      })
      this.axios.post('/Search/ByCriteria/', data).then(resp => {
        this.collectionList = resp.data
      })
    },
    userLoginHandler(isLoggedIn) {
      this.isLoggedIn = isLoggedIn
    },
  },
  mounted() {
    this.axios.get('/Collection/GetAll/').then(resp => {
      this.collectionList = resp.data
    })

    if (this.eventBus == null) return

    this.globalEventBus.$on('user-logged-in', this.userLoginHandler)
    this.eventBus.$on('performSearch', this.performSearch)
  },
})
</script>

<style lang="scss">
@import 'presentation.scss';
@import '@/css/global.scss';
</style>
