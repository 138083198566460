import axios from 'axios'

const axiosInstance = axios.create({
  headers: {
    post: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  },
})

export default axiosInstance
