<template>
  <div class="select-list">
    <button class="select-list__button" @click.prevent="toggle" v-html="placeholder"></button>
    <div class="select-list__container" :class="{ 'select-list__container--closed': !open }">
      <div class="select-list__option" :class="{ 'select-list__option--selected': selection.includes(item.id) }" v-for="item in list" v-bind:key="item.key" @click.prevent="onSelect(item.id)" v-html="item.name"></div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.component('SelectList', {
  props: {
    eventBus: { default: null, type: Object },
    id: { default: null, type: Number },
    placeholder: { default: '', type: String },
    list: { default: null, type: Array },
    multiSelect: { default: false, type: Boolean },
  },
  data() {
    return {
      selection: [],
      open: false,
    }
  },
  methods: {
    forceClose(id) {
      if(id === this.id) return
      this.open = false
    },
    toggle() {
      this.open = !this.open

      this.eventBus.$emit('forceClose', this.id)
    },
    onSelect(id) {
      if(this.selection.includes(id)) {
        this.selection.splice(this.selection.indexOf(id), 1)
      } else if (this.multiSelect) {
        this.selection.push(id)
      } else {
        this.selection = []
        this.selection.push(id)
      }

      if(this.id === null || this.eventBus == null) return
      this.eventBus.$emit('selectListSelection', this.id, this.selection)
    },
  },
  mounted() {
    this.eventBus.$on('forceClose', this.forceClose)
  },
})
</script>

<style lang="scss">
@import 'select-list.scss';
</style>
