import Vue from 'vue'

Vue.config.productionTip = true

import axiosInstance from './axiosConfig'
Vue.prototype.axios = axiosInstance

const globalEventBus = new Vue()
Vue.prototype.globalEventBus = globalEventBus

/************************/
/* General mount method */
/************************/
const mount = (id, vue) => {
  if (document.getElementById(id) !== null) {
    new Vue({
      el: `#${id}`,
      render: h => h(vue),
    })
  }
}

/*************/
/* Importing */
/*************/
import PresentationContainer from '@components/PresentationContainer/PresentationContainer.vue'

/************/
/* Mounting */
/************/
mount('presentationContainer', PresentationContainer)

import '@/assets/css/tailwind.css'
import '@/css/mana-pivot.scss'
