<template>
  <div class="search-bar">
    <div class="search-bar__toggler" @click.prevent="toggleMobileVisibility">
      Filtrer
    </div>
    <div class="search-bar__container" :class="{'search-bar__container--visible': isVisibleOnMobile}">
      <span class="search-bar__close-button" @click.prevent="toggleMobileVisibility">X</span>
      <div class="search-bar__criteria">
        <input
          class="search-bar__text-search"
          placeholder="Enter searchstring..."
          type="text"
          v-model="textSearch"
          @keyup.enter="performSearch"
        />
      </div>
      <div v-for="criteria in searchCriteria" v-bind:key="criteria.id">
        <span
          class="search-bar__criteria search-bar__criteria--checkbox-container"
          v-if="criteria.type === 'Check'"
        >
          <label
            class="search-bar__label"
            :for="'check' + criteria.id"
            v-html="criteria.placeholder"
          ></label>
          <input
            class="search-bar__checkbox"
            type="checkbox"
            :id="'check' + criteria.id"
            @change="radioTrigger(criteria.id, $event)"
          />
        </span>
        <SelectList
          class="search-bar__criteria"
          v-if="criteria.type === 'Select' || criteria.type === 'MultiSelect'"
          :event-bus="proxyBus"
          :id="criteria.id"
          :placeholder="criteria.placeholder"
          :list="criteria.list"
          :multi-select="criteria.type === 'MultiSelect'"
        />
      </div>
      <div class="search-bar__search-container">
        <button class="search-bar__button" @click.prevent="performSearch">
          Search
        </button>
        <span class="search-bar__criteria search-bar__criteria--text">
          <label
            class="search-bar__label"
            v-html="'Results: ' + resultCount"
          ></label>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

import SelectList from '@components/SelectList/SelectList.vue'

export default Vue.component('SearchBar', {
  components: {
    SelectList,
  },
  props: {
    eventBus: { default: null, type: Object },
    resultCount: { default: null, type: Number },
  },
  data() {
    return {
      proxyBus: new Vue(),
      searchCriteria: [],
      criterias: [],
      textSearch: '',
      isVisibleOnMobile: false,
    }
  },
  methods: {
    toggleMobileVisibility() {
      this.isVisibleOnMobile = !this.isVisibleOnMobile
    },
    radioTrigger(id, event) {
      if (event.target.checked) {
        this.setCriteria(id, [1])
      } else {
        this.setCriteria(id, [])
      }
    },
    setCriteria(id, selection) {
      var item = this.criterias.find(x => x.id === id)
      var index = this.criterias.indexOf(item)
      if (index !== -1 && selection.length === 0) {
        this.criterias.splice(index, 1)
      } else if (index !== -1) {
        this.criterias.find(x => x.id === id).selection = selection
      } else {
        this.criterias.push({ id: id, selection: selection })
      }
    },
    performSearch() {
      this.proxyBus.$emit('forceClose', null)
      this.eventBus.$emit('performSearch', this.criterias, this.textSearch)
    },
  },
  mounted() {
    this.axios.get('/Search/GetCriteria/').then(resp => {
      this.searchCriteria = resp.data
    })

    if (this.proxyBus == null) return
    this.proxyBus.$on('selectListSelection', this.setCriteria)
  },
})
</script>

<style lang="scss">
@import 'search-bar.scss';
</style>
